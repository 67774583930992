import { load, getInstance } from "recaptcha-v3";

export const recaptchaMixin = {
  data() {
    return {
      recaptchaToken: null
    };
  },

  created() {
    this.loadRecaptcha();
  },

  methods: {
    loadRecaptcha() {
      load("6Ld3isMZAAAAANBhhcIYSc_YqvpIokVVgZL-Pnk_").then(r => {
        r.execute("submit").then(token => {
          this.recaptchaToken = token;
          getInstance().hideBadge();
        });
      });
    }
  }
};
